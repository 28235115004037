import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { resetData } from '../../redux/actions/data'
import { setSlide } from '../../redux/actions/layout'

const Score = (props) => {
  const dispatch = useDispatch()

  // State
  const [totalPoint, setTotalPoint] = useState(0)

  // State (Redux)
  const { dataState } = useSelector(
    (state) => ({
      dataState: state.data,
    }),
    shallowEqual
  )

  const { answers, score } = dataState

  // Effect
  useEffect(() => {
    // const score = answers.map((a) => a.mainSelect).reduce((a, b) => a + b, 0)
    setTotalPoint(score)

    setTimeout(() => {
      document.getElementById('text-shown').classList.add('text-hidden')
      document.getElementById('text-hidden').classList.remove('text-hidden')
      document.getElementById('score').classList.add('scored')
      document.getElementById('score-total').classList.add('show')
      document.getElementById('score-btn').classList.add('show')

      setTimeout(() => {
        dispatch(resetData())
      }, 1000)
    }, 3000)
  }, [])

  /**
   * 홈으로 가기  (새로고침)
   */
  const goHome = () => {
    location.reload()
    /* dispatch(setSlide(0))
    dispatch(resetData())
    props.sectionSliderRef.current.slickGoTo(0) */
  }

  return (
    <div className="page bg-yellow">
      <div className="relative" style={{ height: '10rem' }}>
        <p
          id="text-shown"
          className="absolute text-2xl font-semibold text-toggle"
        >
          My Depression
          <br />
          Self-Check
          <br />
          자가 진단 점수를
          <br />
          계산하고 있습니다
        </p>
        <p
          id="text-hidden"
          className="absolute text-2xl font-semibold text-toggle text-hidden"
        >
          My Depression
          <br />
          Self-Check
          <br />
          진단이 완료되었습니다
          <br />
          나의 자가 진단 점수는...
        </p>
      </div>
      <div className="flex justify-center items-center">
        <div id="score" className="score ">
          <div>
            <div className="score-box">
              <div id="score-loading" className="loading" />
            </div>
            <div
              id="score-total"
              className="w-full text-center font-bold total-score init-hidden"
            >
              {totalPoint}
            </div>
          </div>
        </div>
      </div>
      <footer id="score-btn" className="init-hidden">
        <div className="footer-content">
          <ul>
            <li className="active">
              <button
                type="button"
                className="footer-link"
                onClick={() => goHome()}
              >
                홈으로
              </button>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default Score
