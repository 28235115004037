import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setPersonalInfo } from '../../redux/actions/data'

const genderOptions = [
  {
    name: '여성',
    value: '여성',
  },
  {
    name: '남성',
    value: '남성',
  },
  {
    name: '알 수 없음',
    value: '알 수 없음',
  },
]

const Gender = (props) => {
  const dispatch = useDispatch()

  // State
  const [busy, setBusy] = useState(false)
  const [gender, setGender] = useState('')

  // State (Redux)
  const { dataState } = useSelector(
    (state) => ({ dataState: state.data }),
    shallowEqual
  )
  const { personalInfo } = dataState

  // Effect
  useEffect(() => {
    setGender(personalInfo.gender)
  }, [personalInfo])

  /**
   * 퀴즈 시작 (section1 종료)
   */
  const startQuiz = () => {
    if (!gender) return

    const updatedPersonalInfo = personalInfo
    updatedPersonalInfo.gender = gender
    dispatch(setPersonalInfo(updatedPersonalInfo))
    props.sectionSliderRef.current.slickNext()
  }

  return (
    <div className="page bg-yellow">
      <div style={{ marginBottom: '7.9375rem' }}>
        <p className="text-2xl font-semibold">성별을 선택해주세요</p>
      </div>
      <div style={{ marginBottom: '3.75rem' }}>
        <ul className="flex space-x-3">
          {genderOptions.map((g) => (
            <li
              key={g.value}
              onClick={() => setGender(g.value)}
              className={`flex-grow selector ${
                gender === g.value ? 'active' : ''
              }`}
              style={{ width: '33.3333%' }}
            >
              {g.name}
            </li>
          ))}
        </ul>
      </div>
      <footer>
        <div className="footer-content">
          <ul>
            <li className="active">
              <button
                type="button"
                className="footer-link"
                onClick={() => props.sectionSliderRef.current.slickPrev()}
              >
                이전
              </button>
            </li>
            <li className={`${!gender ? '' : 'active'}`}>
              <button
                type="button"
                onClick={() => startQuiz()}
                className="footer-link"
                disabled={busy}
              >
                다음
              </button>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default Gender
