import axios from 'axios'
import * as actionTypes from '../../actionTypes'

const { REACT_APP_API_URI } = process.env

/**
 * 설문조사 등록
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendSurvey = async (req) => {
  // await axios.post(`${REACT_APP_API_URI}${actionTypes.SURVEY_URL}`, req)
  const { saveApi } = req
  return axios.post(`${saveApi}`, req, {
    headers: {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb21haW4iOiJ2ZHAifQ.PHQDX5tmztCvqVJ8pjk7e8ehzvP8RCO2GXSioJijCSQ`,
    },
  })
}

export const storeSurvey = (params) => {
  return axios.post(`${REACT_APP_API_URI}/cesd`, params)
}

/**
 * 개인정보 설정
 * @param info
 * @returns {(function(*): void)|*}
 */
export const setPersonalInfo = (info) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_PERSONAL_INFO,
    payload: info,
  })
}

/**
 * 현재 퀴즈 설정
 * @param quizIndex
 * @returns {(function(*): void)|*}
 */
export const setCurrentQuiz = (quizIndex) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_CURRENT_QUIZ,
    payload: quizIndex,
  })
}

/**
 * 현재 퀴즈 설정
 * @param req
 * @returns {(function(*): void)|*}
 */
export const answerQuiz = (req) => (dispatch) => {
  return dispatch({
    type: actionTypes.ADD_ANSWER,
    payload: req,
  })
}

/**
 * 총 스코어 저장
 * @param score
 * @returns {(function(*): void)|*}
 */
export const setScore = (score) => (dispatch) => {
  return dispatch({
    type: actionTypes.TOTAL_SCORE_STORE,
    payload: score,
  })
}

/**
 * 모든 데이터 초기화
 * @returns {function(*): *}
 */
export const resetData = () => (dispatch) => {
  return dispatch({
    type: actionTypes.RESET_ALL,
  })
}

/**
 * Audio queue 추가
 * @param req
 * @returns {(function(*): void)|*}
 */
export const addAudio = (req) => (dispatch) => {
  return dispatch({
    type: actionTypes.ADD_AUDIO,
    payload: req,
  })
}
