import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import Cookies from 'js-cookie'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Intro from '../slides/section1/intro'
import NameDob from '../slides/section1/nameDob'
import Gender from '../slides/section1/gender'
import * as actionTypes from '../redux/actionTypes'
import Start from '../slides/section1/start'
import Quiz from '../slides/section1/quiz'
import Score from '../slides/section1/score'
import Finish from '../slides/section1/finish'
import ConfirmHome from '../components/confirmHome'
import { setSlide, toggleConfirmHome } from '../redux/actions/layout'

const Section1 = () => {
  const dispatch = useDispatch()

  const configSlick = {
    dots: false,
    lazyLoad: true,
    arrows: false,
    infinite: false,
    swipe: false,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange(current) {
      dispatch(setSlide(current))
    },
  }

  // Ref
  const sectionSliderRef = useRef(null)

  // State (Redux)
  const { layoutState } = useSelector(
    (state) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const personalInfo = useSelector(
    (state) => ({
      layoutState: state.personalInfo,
    }),
    shallowEqual
  )
  const { slide } = layoutState

  // Effect
  useEffect(() => {
    // 슬라이드 설정
    const slideKey = Number(Cookies.get(actionTypes.SLIDE_KEY))

    if (Cookies.get(actionTypes.SLIDE_KEY)) {
      dispatch(setSlide(slideKey))
      sectionSliderRef.current.slickGoTo(slideKey)
    }
  }, [sectionSliderRef])

  return (
    <div id="section1" className="section">
      <Slider ref={sectionSliderRef} {...configSlick}>
        <Intro sectionSliderRef={sectionSliderRef} />
        {!personalInfo.name && !personalInfo.dob && (
          <NameDob sectionSliderRef={sectionSliderRef} />
        )}
        {!personalInfo.gender && <Gender sectionSliderRef={sectionSliderRef} />}
        <Start sectionSliderRef={sectionSliderRef} />
        <Quiz sectionSliderRef={sectionSliderRef} />
        <Score sectionSliderRef={sectionSliderRef} />
      </Slider>
      <ConfirmHome sectionSliderRef={sectionSliderRef} />
      {slide && slide < 5 ? (
        <button
          type="button"
          onClick={() => dispatch(toggleConfirmHome(true))}
          className="btn btn-home absolute top-5 right-5"
        >
          Go home
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Section1
